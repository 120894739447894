var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tfoot',[_c('tr',[(_vm.template.crtNo)?_c('td'):_vm._e(),(_vm.template.selectable)?_c('td'):_vm._e(),(
        _vm.template.columns[0].meta.visible
          && !_vm.template.columns[0].meta.hidden
      )?_c('td',{staticClass:"has-text-centered is-bold"},[_vm._v(" "+_vm._s(_vm.i18n("Total"))+" ")]):_vm._e(),_vm._l((_vm.visibleColumns.length - 1),function(i){return (
        _vm.visibleColumns[i].meta.visible
          && !_vm.visibleColumns[i].meta.hidden
          && !_vm.visibleColumns[i].meta.rogue
      )?_c('td',{key:i,class:[
        'is-bold',
        { 'is-money' : _vm.visibleColumns[i].money },
        _vm.visibleColumns[i].align
          ? _vm.template.aligns[_vm.visibleColumns[i].align]
          : _vm.template.align
      ]},[(_vm.visibleColumns[i].meta.total)?_c('span',[_vm._v(" "+_vm._s(_vm.visibleColumns[i].money ? _vm.body.total[_vm.visibleColumns[i].name] : _vm.numberFormat(_vm.body.total[_vm.visibleColumns[i].name]))+" ")]):(_vm.visibleColumns[i].meta.customTotal)?_vm._t(((_vm.visibleColumns[i].name) + "_custom_total"),function(){return [_vm._v(" "+_vm._s(((_vm.visibleColumns[i].name) + "_custom_total"))+" ")]}):_vm._e()],2):_vm._e()}),(_vm.template.actions)?_c('td'):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }