import {
  Romanian,
} from 'flatpickr/dist/l10n/ro';
import {
  english,
} from 'flatpickr/dist/l10n/default';
import {
  German,
} from 'flatpickr/dist/l10n/de';
import {
  Dutch,
} from 'flatpickr/dist/l10n/nl';
import {
  French,
} from 'flatpickr/dist/l10n/fr';
import {
  Portuguese,
} from 'flatpickr/dist/l10n/pt';
import {
  Arabic,
} from 'flatpickr/dist/l10n/ar';
import {
  Mongolian,
} from 'flatpickr/dist/l10n/mn';
import {
  Hungarian,
} from 'flatpickr/dist/l10n/hu';

export default {
  ro: Romanian,
  en: english,
  de: German,
  nl: Dutch,
  fr: French,
  br: Portuguese,
  ar: Arabic,
  mn: Mongolian,
  hu: Hungarian,
};
