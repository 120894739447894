var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{class:[
      'input',
      { 'is-danger': _vm.isDanger },
      { 'is-warning': _vm.isWarning }
    ],attrs:{"type":"text","placeholder":_vm.placeholder,"name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value}}),_c('span',{staticClass:"icon is-small is-left"},[(_vm.timeOnly)?_c('fa',{attrs:{"icon":"clock"}}):_c('fa',{attrs:{"icon":"calendar-alt"}})],1),(_vm.value && !_vm.disabled && !_vm.isDanger && !_vm.isWarning)?_c('span',{staticClass:"icon is-small is-right clear-button",on:{"click":function($event){return _vm.picker.clear()}}},[_c('a',{staticClass:"delete is-small"})]):_vm._e(),(_vm.isDanger || _vm.isWarning)?_c('span',{class:[
      'icon is-small is-right',
      { 'has-text-danger': _vm.isDanger },
      { 'has-text-warning': _vm.isWarning }
    ]},[_c('fa',{attrs:{"icon":"exclamation-triangle"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }